// extracted by mini-css-extract-plugin
export var article = "article-module--article--FoQuy";
export var article__tags = "article-module--article__tags--+XPqf";
export var article__tag = "article-module--article__tag--hXKLI";
export var article__title = "article-module--article__title--YetVr";
export var article__note = "article-module--article__note--UYrmq";
export var article__authors = "article-module--article__authors--5UBRJ";
export var article__author = "article-module--article__author--dG42n";
export var article__authorAvatar = "article-module--article__author-avatar--TeiIB";
export var article__authorName = "article-module--article__author-name--6L+wg";
export var article__authorPosition = "article-module--article__author-position--+7vih";
export var article__blocks = "article-module--article__blocks--9cJ2A";
export var article__slider = "article-module--article__slider--l8Psn";
export var article__sliderContainer = "article-module--article__slider-container--O3OeG";
export var article__blocksCountersRow = "article-module--article__blocks-counters-row--HKflt";
export var article__blocksButton = "article-module--article__blocks-button--Hc5vV";
export var article__blocksCountersRow_1 = "article-module--article__blocks-counters-row_1--peqkS";
export var article__blocksCountersRow_2 = "article-module--article__blocks-counters-row_2--21LdR";
export var article__blocksCountersRow_3 = "article-module--article__blocks-counters-row_3--6doGi";
export var article__blocksCountersRow_4 = "article-module--article__blocks-counters-row_4--8jWgz";
export var article__blocksTable = "article-module--article__blocks-table--bIES8";
export var article__blocksTableRow = "article-module--article__blocks-table-row--s1+Nk";
export var article__blocksTableCell = "article-module--article__blocks-table-cell--Pd+yX";
export var article__blocksPersonality = "article-module--article__blocks-personality--6jqWV";
export var article__blocksPersonalityImage = "article-module--article__blocks-personality-image--96JWc";